<template>
	<div class="achieve_chl_parent_wr full-height-wr">
		<!-- Show module's progress -->
		<AppModulesProgress
			v-if="latestAvailableStage"
			v-model="latestAvailableStage.module_id"
			:events="events"
		></AppModulesProgress>

		<v-row v-if="questionnaire" no-gutters class="notes-parent-container-wr">
			<v-col v-if="achievements.length">
				<div
					v-for="(item, index) in achievements"
					:key="index"
					class="d-inline-block mx-2"
				>
					<vue-resizable
						class="resizable"
						ref="resizableComponent"
						dragSelector=".drag-container-wr"
						:active="[]"
						:width="170"
						:height="150"
						:left="0"
						:top="0"
					>
						<div class="drag-container-wr">
							<v-form :ref="'form-' + item.id">
								<v-textarea
									v-model="item.answer"
									solo
									auto-grow
									flat
									no-resize
									class="elevation-0"
									background-color="success"
									color="white"
									:placeholder="$t('app.placeholder.type_achievements_here')"
									:rules="
										item.question.required === 'true' ? [rules.required] : []
									"
									@blur="submitQuestionnaire(item)"
								>
								</v-textarea>
							</v-form>
						</div>
					</vue-resizable>
				</div>
			</v-col>

			<v-col v-if="challenges.length" class="text-end">
				<div
					v-for="(item, index) in challenges"
					:key="index"
					class="d-inline-block mx-2"
				>
					<vue-resizable
						class="resizable"
						ref="resizableComponent"
						dragSelector=".drag-container-wr"
						:active="[]"
						:width="170"
						:height="150"
						:left="0"
						:top="0"
					>
						<div class="drag-container-wr">
							<v-form :ref="'form-' + item.id">
								<v-textarea
									v-model="item.answer"
									solo
									flat
									no-resize
									class="elevation-0"
									auto-grow
									background-color="info"
									:placeholder="$t('app.placeholder.type_challenges_here')"
									:rules="
										item.question.required === 'true' ? [rules.required] : []
									"
									@blur="submitQuestionnaire(item)"
								>
								</v-textarea>
							</v-form>
						</div>
					</vue-resizable>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from "vuex";
import rules from "@/utils/validation";
import AppModulesProgress from "@/components/ui/AppModulesProgress.vue";
import { isArrayHavingItem, filterArray } from "@/utils/helpers";

export default {
	name: "AchievementsAndChallenges",

	data() {
		return {
			rules,
		};
	},

	components: {
		AppModulesProgress,
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.init();
	},

	computed: {
		...mapState({
			questionnaire: (state) => state.questionnaire.list,
		}),

		achievements() {
			return this.questionnaire.answers.filter((answer) => {
				return isArrayHavingItem([1, 2, 3], answer.question.order);
			});
		},

		challenges() {
			return this.questionnaire.answers.filter((answer) => {
				return isArrayHavingItem([4, 5, 6], answer.question.order);
			});
		},

		latestAvailableStage() {
			return this.$store.getters["user/getLatestAvailableStage"]();
		},

		events: function() {
			let response = this.$store.getters["planAndManage/getModulePlan"]();

			// Do not show module 6
			return filterArray(response, "module_id", 6);
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("finish-achieve-chl", (payload) => {
				this.validate();
			});
		},

		async init() {
			try {
				this.$loader.start();
				// Get user's plan
				await this.getUserPlan();
				// Get required questionnaire
				await this.getQuestionnaire();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getUserPlan() {
			await this.$store.dispatch("planAndManage/getUserPlan");
		},

		async validate() {
			try {
				this.$loader.start();

				// Check how many required questions are left unanswered
				let unanswered = this.questionnaire.answers.filter((item) => {
					return item.question.required === "true" && !item.answer;
				});

				if (unanswered.length) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				} else {
					this.$eventBus.$emit("ac-redirect-next");
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getQuestionnaire() {
			await this.$store.dispatch("questionnaire/list", {
				questionnaire_name: this.$defines.QUESTIONNAIRE.q81,
			});
		},

		async submitQuestionnaire(payload) {
			try {
				// If old answer and new answers are same then return
				if (!this.$refs["form-" + payload.id][0].validate()) return false;

				if (payload.answer === payload.formated_answer) return;

				await this.$store.dispatch("questionnaire/submitAnswer", {
					questionnaire_id: payload.id,
					answer: payload.answer,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("finish-achieve-chl");
	},
};
</script>

<style scoped>
.achieve_chl_parent_wr {
	background-repeat: no-repeat;
	position: relative;
}

.notes-parent-container-wr {
	position: absolute;
	width: 96%;
	bottom: 0;
}

>>> ::placeholder {
	color: white !important;
	opacity: 1 !important;
}

>>> textarea {
	color: white !important;
}
</style>
