<template>
	<div>
		<StageIntroDialog />

		<AchievementsAndChallenges></AchievementsAndChallenges>

		<!-- Show finish source evaluation  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.achievements_and_challenges.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import AchievementsAndChallenges from "@/components/research/modules/reflectJourney/stages/achieveAndChl/Index.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";

export default {
	name: "AchievementsAndChallengesPage",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		AchievementsAndChallenges,
		StageFinishDialog,
		StageIntroDialog,
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("ac-redirect-next", (payload) => {
				this.dialog = true;
			});
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("ac-redirect-next");
	},
};
</script>
